<template>
  <div
    v-tippy="{ enabled: showTooltip, content: tooltipContent, zIndex: 'auto' }"
    class="svg-bar-cont">
    <svg
      ref="svg"
      :viewBox="`0 0 ${width*scale} ${height*scale}`"
      :class="{ interactive: showAxis }"
      width="100%"
      height="100%"
      preserveAspectRatio="xMinYMin meet"
      class="bar-chart">
      <g :transform="'translate('+calculatedMargin.left+','+calculatedMargin.top+')'" class="bar-chart__wrapper">
        <g v-if="showAxis" class="axis">
          <g ref="xAxis" :transform="'translate(0,'+chartHeight+')'" class="axis-x" />
          <g ref="yAxis" class="axis-y" />
        </g>
        <g
          v-for="(d, index) in yData"
          :key="`bar-${index}-${yData.length}`">
          <rect
            :fill="colorize('fillColor')"
            :stroke="colorize('strokeColor')"
            :stroke-width="strokeWidth"
            :x="x(xData[index]) + x.bandwidth() / 8"
            :y="y(d)"
            :width="x.bandwidth() / 4 * 3"
            :height="y(0) - y(d)"
            class="bar-chart__bar" />
        </g>
        <path
          v-if="showTooltip"
          ref="mouseLine"
          :key="`mouse-line-${yData.length}`"
          :transform="`translate(${x.bandwidth()/2} 0)`"
          class="line-chart__mouse-line"
          d=""
          stroke-dasharray="1.5"
          stroke-width="1"
          stroke="#525662" />
      </g>
    </svg>
  </div>
</template>

<script>
import chartsMixin from './charts';
import { line } from 'd3-shape';
import { select } from 'd3-selection';

export default {
  name: 'BarChart',
  mixins: [chartsMixin],
  computed: {
    yData() {
      return this.resetData && this.animate
        ? new Array(this.data[this.yAxisKey].length).fill(0)
        : this.data[this.yAxisKey];
    },
    line() {
      return line()
        .x(d => this.x(d.label))
        .y(d => this.y(d.value));
    }
  },
  watch: {
    'mouseData.label'(mouseData) {
      this.drawMouseLine(mouseData);
    }
  },
  methods: {
    onChartUpdated() {
      if (this.showAxis) {
        this.renderAxis();
      }
    },
    renderAxis() {
      select(this.$refs.xAxis).call(this.axisBottom);
      select(this.$refs.yAxis).call(this.axisLeft);
    },
    drawMouseLine() {
      if (this.mouseData.label === null) {
        this.$refs.mouseLine.removeAttribute('d');
      } else {
        const domain = this.y.domain();
        this.$refs.mouseLine.setAttribute(
          'd',
          this.line([
            { label: this.mouseData.label, value: domain[0] },
            { label: this.mouseData.label, value: domain[1] }
          ])
        );
      }
    }
  }
};
</script>

<style lang="scss">
.svg-bar-cont {
  display: inline-block;
  position: relative;
  width: 100%;

  .bar-chart {
    outline: transparent;
    user-select: none;
    height: auto;

    .axis {
      text {
        fill: $--clb-body-font;
        font-size: 11px;
        -webkit-font-smoothing: antialiased;
      }

      .domain {
        stroke: transparent;
      }

      .tick {
        line {
          stroke: $--clb-disabled-color;
        }
      }

      .axis-x {
        text {
          font-size: 11px;
          text-anchor: middle;
          transform: translate(-5px, 6px) rotate(0deg);
          -webkit-font-smoothing: antialiased;
        }
      }
    }

    &__bar {
      transition: all 0.4s ease-out, opacity 0.2s, stroke 0.3s ease, fill 0.3s ease;
      outline: transparent;
    }

    .interactive .bar-chart__wrapper:hover .bar-chart__bar:not(:hover) {
      fill: $--clb-bg-accent-color;
      stroke: $--clb-disabled-color;
    }

    .interactive .bar-chart__wrapper:hover .bar-chart__bar:hover {
      fill: $--clb-color-primary__dark;
    }
  }
}

@media (max-width: 450px) {
  .svg-bar-cont {
    .bar-chart {
      .axis {
        text,
        .axis-x text {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
