<template>
  <div :class="{ 'performance-metrics-overview-list-item--active': active }" class="performance-metrics-overview-list-item">
    <div :key="`container-key-${metricKey}`" class="performance-metrics-overview-list-item__chart">
      <line-chart
        :animate="animateChart"
        :show-axis="false"
        :show-circle="false"
        :show-tooltip="false"
        :data="metricData"
        :width="120"
        :height="60"
        :stroke-width="2"
        :zero-scale="true"
        stroke-color="#ececec" />
    </div>
    <div class="performance-metrics-overview-list-item__details">
      <div class="performance-metrics-overview-list-item__details-name">
        {{ metricName }}
      </div>
      <div class="performance-metrics-overview-list-item__details-metric">
        {{ metricSum }}
      </div>
      <small v-if="metricDiff !== ''">{{ metricDiff }}% vs similar</small>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';

export default {
  name: 'PerformanceMetricsOverviewListItem',
  components: {
    LineChart
  },
  props: {
    metricKey: {
      type: String,
      required: true
    },
    metricName: {
      type: String,
      required: true
    },
    metricData: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    metricSum: {
      required: true
    },
    metricDiff: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    animateChart() {
      return this.$deviceType !== 'mobile';
    }
  }
};
</script>

<style lang="scss">
.performance-metrics-overview-list-item {
  position: relative;
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: space-between;

  &__details {
    position: relative;
    flex: 1;
    width: 100%;
    z-index: 1;

    &-name,
    &-metric {
      padding: 0;
      margin: 0;
      color: $--clb-body-font;
    }

    &-name {
      font-size: $--clb-font-size-xs - 2px !important;
      line-height: 12px;
      font-weight: 400;
    }

    &-metric {
      font-size: $--clb-font-size-sm !important;
      font-weight: 700;
      color: $--clb-color__headings;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__chart {
    width: 80%;
    height: 40px;
    max-width: 100%;
    display: flex;
    align-items: center;

    path {
      transition: stroke 0.3s ease-in;
    }
  }

  &--active {
    .performance-metrics-overview-list-item__chart path {
      stroke: $--clb-color-primary;
    }
  }

  &:hover,
  &--active:hover {
    .performance-metrics-overview-list-item__details {
      &-metric,
      &-name {
        color: $--clb-color__headings;
      }
    }
  }

  &::before {
    content: '';
    z-index: 1;
    width: 100%;
    max-width: 128px;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
    display: none;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), cubic-bezier(0.445, 0.05, 0.55, 0.95), rgba(255, 255, 255, 1));
  }
}

@media (min-width: $--xs) {
  .performance-metrics-overview-list-item {
    &__details {
      &-name {
        font-size: $--clb-font-size-xs !important;
      }

      &-metric {
        font-size: $--clb-font-size-base !important;
      }
    }

    &__chart {
      min-width: 60px;
      max-width: 80px;
    }
  }
}

@media (min-width: $--sm) {
  .performance-metrics-overview-list-item {
    padding: $--clb-space-1 0;

    &__chart {
      position: absolute;
      left: 0;
    }
  }
}

/** Switch to $--lg when adv sidebar is live */
@media (min-width: $--md) {
  .performance-metrics-overview-list-item {
    flex-direction: row-reverse;
    height: 100%;
    padding: 0;

    &__chart {
      left: initial;
      right: 0;
      height: 100%;
      width: auto;
      max-width: 90px;
    }

    &::before {
      display: block;
      left: -$--clb-layout-1;
    }
  }
}
</style>

<style lang="scss">
.jb-mobile-device {
  .performance-metrics-overview-list-item {
    &__chart {
      path {
        transition: none;
      }
    }
  }
}
</style>
