<template>
  <div v-loading="loading" v-scrollable:[scrollableArg] class="performance-metrics-visualization">
    <performance-metrics-overview-list
      :overview-data="overviewData"
      :active-metric-key="metricKey"
      @metric-selected="handleMetricSelected" />
    <component
      :is="chartComponent"
      :key="chartKey"
      :data="selectedMetricOverviewData"
      :width="640"
      :height="360"
      :stroke-width="strokeWidth"
      :show-circle="true"
      :animate="animateMainChart"
      :stroke-color="chartStrokeColor"
      :show-area="true"
      :zero-scale="zeroScale"
      class="performance-metrics-main-chart"
      fill-color="#5072ff" />
    <performance-metrics-ranking
      :selected-metric-key="metricKey"
      :rankings="selectedMetricRanking"
      @campaign-click="handleCampaignClick" />
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart';
import LineChart from '@/components/charts/LineChart';
import PerformanceMetricsOverviewList from '@/components/visualizations/PerformanceMetricsOverviewList';
import PerformanceMetricsRanking from '@/components/visualizations/PerformanceMetricsRanking';

export default {
  name: 'PerformanceMetricsVisualization',
  components: {
    LineChart,
    BarChart,
    PerformanceMetricsOverviewList,
    PerformanceMetricsRanking
  },
  props: {
    overviewData: {
      type: Object,
      required: true
    },
    metricKey: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    animateMainChart() {
      return document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#AnimationEventsAttribute', '1.0');
    },
    scrollableArg() {
      return this.$supportsTouch ? 'x' : '';
    },
    selectedMetric() {
      return this.overviewData[this.metricKey];
    },
    chartKey() {
      return `main-chart-${this.metricKey}-${Math.random() * this.selectedMetric.values.length}`;
    },
    chartComponent() {
      return `${['conversion_rates', 'tests', 'epc'].includes(this.metricKey) ? 'line' : 'bar'}-chart`;
    },
    strokeWidth() {
      return this.chartComponent === 'line-chart' ? 1 : 0;
    },
    zeroScale() {
      return this.chartComponent !== 'line-chart';
    },
    selectedMetricOverviewData() {
      return {
        labels: this.overviewData.labels,
        values: this.selectedMetric.values,
        formattedValues: this.selectedMetric.formatted_values
      };
    },
    selectedMetricRanking() {
      return this.selectedMetric.rankings;
    },
    chartStrokeColor() {
      return this.chartComponent === 'line-chart' ? '#5072ff' : 'transparent';
    }
  },
  methods: {
    handleMetricSelected(metricKey) {
      this.$emit('handle-metric-change', metricKey);
    },
    handleCampaignClick(campaignId) {
      this.$emit('campaign-click', campaignId);
    }
  }
};
</script>

<style lang="scss">
.performance-metrics-visualization {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: normal;

  @apply tw-bg-white;

  .performance-metrics-overview-list {
    grid-area: list;
  }

  .performance-metrics-main-chart {
    grid-area: chart;
    display: flex;
    align-items: flex-end;

    > svg {
      display: flex;
      height: auto;
    }
  }

  .performance-metrics-ranking {
    grid-area: ranking;
    display: none;
  }
}

@media (min-width: $--sm) {
  .performance-metrics-visualization {
    display: grid;
    grid-gap: 10px;
    grid-template: 'list chart' 310px/1fr minmax(300px, 552px);

    .no-data {
      top: 100px;
    }
  }
}

@media (min-width: 860px) {
  .performance-metrics-visualization {
    grid-template: 'list chart ranking' 310px/1fr minmax(300px, 552px) 1fr;

    .performance-metrics-ranking {
      display: block;
    }
  }
}

/** Uncomment when adv sidebar is live */
// @media (min-width: $--md) {
//   .performance-metrics-visualization {
//     grid-template: 'list chart' 310px/1fr minmax(300px, 552px);

//     .performance-metrics-ranking {
//       display: none;
//     }
//   }
// }

// @media (min-width: 1250px) {
//   .performance-metrics-visualization {
//     grid-template: 'list chart ranking' 310px/1fr minmax(300px, 552px) 1fr;

//     .performance-metrics-ranking {
//       display: block;
//     }
//   }
// }
</style>
