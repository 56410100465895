<template>
  <el-dropdown
    v-if="isA2Advertiser"
    :disabled="disabled || loading"
    class="export-button"
    size="small"
    trigger="click"
    split-button
    @command="handleClickExportCsv"
    @click="() => handleClickExportCsv('reporting')">
    <span v-if="loading" class="el-icon-loading" />
    <download-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-700 tw-align-middle" />
    Export CSV
    <el-dropdown-menu slot="dropdown" :visible-arrow="false">
      <el-dropdown-item command="reporting">
        Reporting Breakdown
      </el-dropdown-item>
      <el-dropdown-item command="transactions">
        Transaction IDs
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
  <app-button
    v-else
    :loading="loading"
    :disabled="disabled || loading"
    class="hover:tw-underline tw-p-0 tw-m-0 tw-self-end"
    state="text"
    @click="() => handleClickExportCsv('reporting')">
    <download-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-700 tw-align-middle" />
    <span class="tw-font-medium tw-text-sm"> Export CSV </span>
  </app-button>
</template>

<script>
import AppButton from '@/components/AppButton';
import DownloadIcon from '@/assets/svg/download-icon.svg';

export default {
  name: 'CampaignExportButton',
  components: {
    AppButton,
    DownloadIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    activeDateOptions: {
      type: Object,
      required: true
    },
    campaignId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    isA2Advertiser() {
      return this.$store.getters.userSubType === 'a2' && this.$store.getters.userIsAdvertiser;
    },
    endpoint() {
      return this.campaignId ? 'downloadReportingCampaignCsv' : 'downloadReportingCampaignsCsv';
    },
    campaignString() {
      return this.campaignId ? ` (${this.campaignId})` : '';
    }
  },
  methods: {
    handleClickExportCsv(command) {
      if (this.loading || this.disabled) return;

      this.loading = true;
      this.$store
        .dispatch(this.endpoint, {
          orgId: this.orgId,
          type: command,
          campaignId: this.campaignId,
          options: {
            ...this.activeDateOptions,
            format: 'csv'
          },
          filename: `${this.$options.filters.capitalize(command)}${this.campaignString} - ${
            this.activeDateOptions.dateRange
          }@${this.filenameSafeDate()}.csv`
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$store.dispatch('showErrorMessage', 'Failed to Download CSV');
        });
    },
    filenameSafeDate() {
      return new Date()
        .toLocaleString()
        .replace(/\//g, '-')
        .replace(/, /g, ' ')
        .replace(/:/g, '-');
    }
  }
};
</script>

<style lang="scss">
.export-button {
  line-height: 16px;

  .el-button-group {
    display: flex;
  }

  .el-button:hover {
    background: #f3f5fd;
  }

  .el-button:active,
  .el-button.active,
  .el-button:focus,
  .el-button:hover {
    outline: none;
    color: #5072ff;
    transform: translateY(0);
  }

  &[disabled='disabled'] .el-button {
    color: #bababa !important;
    background: $--clb-disabled-color !important;
    border-color: $--clb-disabled-color !important;
    outline: transparent !important;
    font-weight: normal !important;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }
}
</style>
