<template>
  <div class="performance-metrics-ranking">
    <div class="metric-header">
      <h5>Top {{ type | capitalize }}s</h5>
    </div>
    <div class="metric-content">
      <div
        v-for="(item, index) in items"
        :key="`ranking-item-${index}`"
        :class="{pointer: type === 'campaign'}"
        class="ranking-item">
        <p>{{ item.label.substring(0, 50) }}</p>
        <div class="ranking-bar-container">
          <el-progress :percentage="itemPercentages[index]" :stroke-width="6" :show-text="false" /><small>{{ item.formatted_value }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerformanceMetricsRanking',
  props: {
    rankings: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      return this.rankings.list.length ? this.rankings.list : [{ label: 'No Traffic', value: 0 }];
    },
    itemPercentages() {
      return this.items.map(item => (this.items[0].value > 0 ? item.value / this.items[0].value : 0) * 100);
    },
    type() {
      return this.rankings.label;
    }
  },
  methods: {
    handleRankingClick(item) {
      if (item && this.type === 'campaign') {
        this.$emit('campaign-click', item.label);
      }
    }
  }
};
</script>

<style lang='scss'>
.performance-metrics-ranking {
  display: flex;
  flex-direction: column;
  margin-left: $--clb-space-3;

  .metric-header {
    width: 100%;

    h5 {
      margin: 0 0 $--clb-space-6 0;
    }
  }

  .metric-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ranking-item {
      width: 100%;
      margin-bottom: 12px;

      p {
        line-height: 12px;
        font-size: $--clb-font-size-xs;
      }

      .ranking-bar-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .el-progress {
          margin: 0;
          width: calc(100% - 42px);
          height: 16px;
        }

        .el-progress-bar__outer,
        .el-progress-bar__inner {
          border-radius: 0;
        }

        small {
          font-size: 10px;
          line-height: 12px;
          width: 42px;
          text-align: right;
          font-weight: 300;
        }
      }

      &:hover {
        p {
          color: #262626;
        }

        .el-progress-bar__inner {
          background-color: $--clb-color-primary__dark;
        }
      }
    }
  }
}
</style>
