<template>
  <div class="performance-metrics-overview-list">
    <portal to="campaign-reporting-earnings">
      <div class="campaign-reporting__header-right">
        <span class="campaign-reporting__header-right-title">{{ paymentType | capitalize }}</span>
        <span class="campaign-reporting__header-right-body">{{ paid }}</span>
      </div>
    </portal>
    <div
      v-for="(metric, metricKey) in metrics"
      :key="`metric-item-${metricKey}`"
      :class="{ 'performance-metrics-overview-list__item-wrapper--active': isActiveMetric(metricKey) }"
      class="performance-metrics-overview-list__item-wrapper"
      @click="handleMetricItemClick(metricKey)">
      <performance-metrics-overview-list-item
        :key="`performance-metric-list-item-${metricKey}`"
        :active="isActiveMetric(metricKey)"
        :metric-key="metricKey"
        :metric-data="metric.data"
        :metric-sum="metric.sum"
        :metric-name="metric.name" />
    </div>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import PerformanceMetricsOverviewListItem from '@/components/visualizations/PerformanceMetricsOverviewListItem';

const METRICS = {
  sales: {
    name: 'Sales'
  },
  clicks: {
    name: 'Clicks'
  },
  conversion_rates: {
    name: 'Conv. Rate'
  }
};

export default {
  name: 'PerformanceMetricsOverviewList',
  components: { PerformanceMetricsOverviewListItem, SkeletonBox },
  props: {
    overviewData: {
      type: Object,
      required: true
    },
    activeMetricKey: {
      type: String,
      required: true
    }
  },
  computed: {
    orgType() {
      return this.$store.getters.organization.type;
    },
    metrics() {
      const metrics = (this.orgType === 'advertiser') ? Object.assign({}, METRICS, { tests: { name: 'Tests' } }) : METRICS;
      Object.keys(metrics).forEach(k => {
        metrics[k].data = this.getMetricOverviewData(k);
        metrics[k].sum = this.getMetricOverviewSum(k);
      });
      return metrics;
    },
    isAffiliate() {
      return this.$store.getters.userIsAffiliate;
    },
    paymentType() {
      return this.isAffiliate ? 'earned' : 'paid';
    },
    paid() {
      return this.overviewData !== null && this.overviewData.money ? this.overviewData.money.sum : '$0.00';
    }
  },
  methods: {
    handleMetricItemClick(metricKey) {
      this.$emit('metric-selected', metricKey);
    },
    isActiveMetric(metricKey) {
      return this.activeMetricKey === metricKey;
    },
    getMetricOverviewSum(metricKey) {
      return this.overviewData[metricKey].sum;
    },
    getMetricOverviewData(metricKey) {
      return {
        labels: this.overviewData.labels,
        values: this.overviewData[metricKey].values
      };
    }
  }
};
</script>

<style lang="scss">
.performance-metrics-overview-list {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  margin-right: 0;
  justify-content: space-between;
  padding-top: $--clb-layout-2;

  &__item-wrapper {
    position: relative;
    transition: 0.3s ease;
    height: auto;
    width: 23%;

    &::before {
      content: ' ';
      background-color: transparent;
      position: absolute;
      left: 0;
      top: (-$--clb-layout-2);
      width: 100%;
      height: 4px;
      transition: background-color 0.3s ease-in;
    }

    &--active::before {
      background-color: $--clb-color-primary;
    }

    &:hover::before {
      background-color: $--clb-color-primary__dark;
    }
  }
}

@media (min-width: $--xs) {
  .performance-metrics-overview-list {
    padding-bottom: $--clb-space-3;
  }
}

@media (min-width: $--sm) {
  .performance-metrics-overview-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 0;

    &__item-wrapper {
      height: 25%;
      width: 100%;

      &::before {
        top: initial;
        left: (-$--clb-layout-2);
        width: $--clb-space-1;
        height: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
.jb-mobile-device {
  .performance-metrics-overview-list {
    &__item-wrapper {
      &::before {
        transition: none !important;
      }
    }
  }
}

.campaign-reporting {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $--clb-layout-2 0 $--clb-layout-1 0;
    max-height: 33px;
    min-height: 33px;

    &-right {
      line-height: $--clb-layout-2;
      display: flex;
      padding-top: $--clb-space-1;
      justify-content: flex-start;
      align-items: center;
      min-width: 117px;

      &-title {
        font-size: 12px;
      }
    }
  }
}
</style>
