const defaultTimeFormat = 'YYYY-MM-DD';
export const TIMEFRAME_MAP = {
  '1D': { total: 24, timeUnit: 'hour', format: null },
  YD: { total: 24, timeUnit: 'hour', format: null },
  CW: { total: 7, timeUnit: 'day', format: defaultTimeFormat },
  LW: { total: 7, timeUnit: 'day', format: defaultTimeFormat },
  CM: { total: 30, timeUnit: 'day', format: defaultTimeFormat },
  LM: { total: 31, timeUnit: 'day', format: defaultTimeFormat },
  '3M': { total: 93, timeUnit: 'day', format: defaultTimeFormat },
  '1Y': { total: 365, timeUnit: 'day', format: defaultTimeFormat }
};

export const DATE_PRESETS = {
  '1D': 'Today',
  YD: 'Yesterday',
  CW: 'Current Week',
  LW: 'Last Week',
  CM: 'Current Month',
  LM: 'Last Month',
  C: 'Custom'
};

export const RANGE = Object.keys(TIMEFRAME_MAP);
